import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { path } from "../api";
import * as actions from "../utils/actions";
import {
  restClient,
  restClientWithOutAuthentication,
} from "../utils/restClient";

// import modules
import layout from "./modules/layout";
import adminAuth from "./modules/adminAuth";
import clientAuth from "./modules/clientAuth";
import admins from "./modules/admins";
import users from "./modules/users";
import lotteries from "./modules/lotteries";
import raffles from "./modules/raffles";
import blocks from "./modules/blocks";
import cart from "./modules/cart";
import orders from "./modules/orders";
import transactions from "./modules/transactions";
import systemConfig from "./modules/systemConfig";
import commissions from "./modules/commissions";
import buy from "./modules/buy";
import commissionProfiles from "./modules/commisionProfiles";
import transactionProfiles from "./modules/transactionProfiles";
import reports from "./modules/reports";
import shortUrl from "./modules/shortUrl";
import notifications from "./modules/notifications";

const store = new Vuex.Store({
  state: {
    redirectRoute: null,
    redirectAfterAll: null,
    provinces: [],
    countries: [],
    loading: true,
  },
  mutations: {
    [actions.GET_PROVINCES](state, payload = []) {
      state.provinces = payload;
    },
    [actions.GET_COUNTRY_LIST](state, payload = []) {
      state.countries = payload;
    },
    [actions.SET_LOADING](state, payload = false) {
      state.loading = payload;
    },
  },
  actions: {
    async fetchProvinces({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.admin.provinces.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_PROVINCES, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_PROVINCES, []);
          reject(error);
        }
      });
    },
    async fetchProvincesSelect({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClientWithOutAuthentication(
            path.client.provinces.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_PROVINCES, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_PROVINCES, []);
          reject(error);
        }
      });
    },
    async fetchCountries({ commit }, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.countries.base,
            "GET",
            null,
            payload
          );
          commit(actions.GET_COUNTRY_LIST, response.data.data);
          resolve(response);
        } catch (error) {
          commit(actions.GET_COUNTRY_LIST, []);
          reject(error);
        }
      });
    },
    async sendContactUsEmail(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.contact,
            "POST",
            payload
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async sendProblemReportEmail(_, payload = {}) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.problemReport,
            "POST",
            payload,
            {},
            { "Content-Type": "multipart/form-data" }
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    async fetchAccountByPhone(_, payload) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const response = await restClient(
            path.client.accountByPhone(payload),
            "GET"
          );
          resolve(response);
        } catch (error) {
          reject(error);
        }
      });
    },
    setLoading({ commit }, payload = false) {
      commit(actions.SET_LOADING, payload);
    },
    getFileUrl(_, payload) {
      return path.client.files.single(payload);
    },
  },
  modules: {
    layout,
    adminAuth,
    clientAuth,
    admins,
    users,
    lotteries,
    raffles,
    blocks,
    cart,
    orders,
    transactions,
    systemConfig,
    commissions,
    buy,
    commissionProfiles,
    transactionProfiles,
    reports,
    shortUrl,
    notifications,
  },
  getters: {
    defaultCountry: (state) => state.countries.find((item) => item.is_default),
    getCountries: (state) => state.countries,
  },
});

export default store;
