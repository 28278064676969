import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { firebaseEnabled, firebaseConfig, vapidKey } from "./config.js";
import localStorageService from "../utils/localStorage";
import { apiLsClientVariable } from "../utils/utils";
import store from "../store";

let messaging = null;

if (!firebaseEnabled) {
  console.error("Firebase is not enabled.");
} else {
  initializeApp(firebaseConfig);
  messaging = getMessaging();

  onMessage(messaging, (payload) => {
    console.log("USER Message received. ", payload);
    store.dispatch("notifications/refreshUserNotifications");
  });
}

//----------------

const parseUserNotifications = (notifications) => {
  return notifications.map((notification) => {
    // TODO: Remove unnecessary/sensitive fields ?
    const data = notification.data;
    return {
      ...notification,
      ...data,
    };
  });
};

const storeFCMToken = async (token) => {
  if (!token?.token) removeFCMToken();
  token = window.btoa(JSON.stringify(token));
  localStorageService.set(apiLsClientVariable + "-FCM", token);
  return token;
};

const getFCMToken = async () => {
  if (!firebaseEnabled) return null;
  const token = await getToken(messaging, { vapidKey });
  return token;
};

const removeFCMToken = () => {
  localStorageService.remove(apiLsClientVariable + "-FCM");
  return null;
};

const getUserNuuid = (nuuid = null) => {
  const key = apiLsClientVariable + "-NUUID";
  if (nuuid) {
    localStorageService.set(key, window.btoa(nuuid));
  } else if (localStorageService.get(key)) {
    nuuid = window.atob(localStorageService.get(key));
  }
  return nuuid;
};

const registerToken = async (nuuid = null) => {
  if (!firebaseEnabled) return false;
  if (nuuid) getUserNuuid(nuuid);
  const token = await getFCMToken();
  if (nuuid) store.dispatch("notifications/registerFCMToken", { token });
};

const refreshUserNotifications = () => {
  if (!firebaseEnabled) return false;
  store.dispatch("notifications/refreshUserNotifications");
};

const updateUserNotification = async (notification) => {
  if (!firebaseEnabled) return false;
  store.dispatch("notifications/updateUserNotifications", notification);
};

const deleteUserNotification = async (notification) => {
  if (!firebaseEnabled) return false;
  store.dispatch("notifications/deleteUserNotifications", notification);
};

getFCMToken();

export {
  getFCMToken,
  storeFCMToken,
  removeFCMToken,
  getUserNuuid,
  registerToken,
  refreshUserNotifications,
  updateUserNotification,
  deleteUserNotification,
  parseUserNotifications,
};
